import { useSearchParams } from 'react-router-dom';
import { Container, ContentLabel, SectionLabel } from './styles';

const Terms = () => {
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source');

    const isFromApp = source === 'webview';

    return (
        <Container isFromApp={isFromApp}>
            <SectionLabel>Terms of Service - Steelia AI</SectionLabel>
            <ContentLabel>
                Welcome to Steelia AI! By using our Chat Bot application integrated with GPT API, you agree to comply with and be bound by the following terms and conditions. Please read these Terms of Service carefully before using our application.
            </ContentLabel>

            <SectionLabel>User Agreement</SectionLabel>
            <ContentLabel>
                By accessing or using Steelia AI, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our application.
            </ContentLabel>

            <SectionLabel>User Registration</SectionLabel>
            <ContentLabel>
                <b>1. No Mandatory Registration: </b>Steelia AI does not require user registration for basic access. However, certain features may be limited for unregistered users.
                <br />
                <br />
                <b>2. Paid Subscription: </b>Users can enjoy a free trial for 3 days. To continue using the application, users must subscribe to one of our paid plans.
            </ContentLabel>

            <SectionLabel>User Conduct</SectionLabel>
            <ContentLabel>
                <b>1. Responsible Use: </b>Users are expected to use Steelia AI responsibly and comply with all applicable laws and regulations.
                <br />
                <br />
                <b>2. Prohibited Activities: </b>Users must not engage in any activities that may disrupt the functionality of the application, violate privacy, or compromise security.
            </ContentLabel>

            <SectionLabel>Intellectual Property</SectionLabel>
            <ContentLabel>
                <b>1. Ownership: </b>Steelia AI and its content, including but not limited to text, graphics, logos, and images, are the property of Steelia AI and are protected by intellectual property laws.
            </ContentLabel>

            <SectionLabel>Privacy</SectionLabel>
            <ContentLabel>
                <b>1. Privacy Policy: </b>Users agree to the terms outlined in the Privacy Policy, which govern the collection and use of personal information.
            </ContentLabel>

            <SectionLabel>Termination</SectionLabel>
            <ContentLabel>
                <b>1. Termination of Access: </b>Steelia AI reserves the right to terminate or suspend access to the application at any time without prior notice for violation of these Terms of Service.
            </ContentLabel>

            <SectionLabel>Changes to Terms</SectionLabel>
            <ContentLabel>
                <b>1. Notification: </b>Steelia AI may update these Terms of Service, and users will be notified of any changes. Continued use of the application after such changes constitutes acceptance of the new Terms.
            </ContentLabel>

            <SectionLabel>Contact Us</SectionLabel>
            <ContentLabel>
                If you have any questions or concerns regarding these Terms of Service, please contact us at aisteelia369@gmail.com.
                <br /> <br />
                Thank you for using Steelia AI and abiding by these Terms of Service.
                <br /> <br />
                These Terms of Service are effective from November 1, 2023.
            </ContentLabel>
        </Container>
    );
}

export default Terms;
