import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Privacy from './pages/privacy';
import Terms from './pages/terms';
import DataDeletionRequest from './pages/data-deletion-request';
import Home from './pages/home';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route path="" element={<MainPage />} /> */}
          <Route>
            <Route path="" element={<Home />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/data-deletion-request" element={<DataDeletionRequest />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
